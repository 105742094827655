// cover max 28rem

.cover {

  .cover_container {

    .cover_headlines {
      top: 35%;

      h1 {
        font-size: 2.5rem;

        em {
          font-size: 2.5rem;
        }
      }
    }
  }
}