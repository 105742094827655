.frame.frame-default.frame-type-list.frame-layout-0 {

  .callrotation_container {

    .mobile_version {

      .callrotation_employee {
        grid-template-columns: 1fr;

        p {
          line-height: 1.3;

          &:nth-of-type(odd) {
            margin-top: 0.75rem;
            opacity: 0.6;

            &:first-of-type {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}