// image_textbox max 28rem

.image_textbox {

  .textbox {

    .textbox_header {
      padding: 2rem 1rem;

      h2 {
        font-size: 1.75rem;
        max-width: 100%;

        em {
          font-size: 2rem;
        }
      }
    }

    .textbox_text {
      padding: 2rem 1rem;
    }
  }
}