// divider_cover max 28rem

.divider_cover {

  .divider_cover_container {

    .divider_cover_headlines {
      top: 35%;

      h2 {
        font-size: 1.5rem;

        em {
          font-size: 2rem;
        }
      }
    }
  }
}