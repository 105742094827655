// basic styles
//@import "style_base.scss";

// breakpoint max-width: 28rem
@media screen and (max-width: 28rem) {
  @import "ContentElements/cover/cover_max_28rem.scss";
  @import "ContentElements/divider_cover/divider_cover_max_28rem.scss";
  @import "ContentElements/image_textbox/image_textbox_max_28rem.scss";
  @import "callrotation/callrotation_max_28rem.scss";
}
